import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalService } from 'src/app/services';
import { Rental, VehicleSearchParameters } from '../../models';

@Component({
  selector: '[app-search-component]',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.scss']
})
export class SearchComponentComponent implements OnInit, OnChanges {

  @Input() parameters: VehicleSearchParameters;
  @Output() parametersChange = new EventEmitter<VehicleSearchParameters>();

  years: String[] = [];
  makes: String[] = [];
  models: String[] = [];
  searchForm: FormGroup;
  inventory: Rental[] = [];
  model: any = {};

  constructor(public _formBuilder: FormBuilder, private _localService: LocalService) { }
  async ngOnInit(): Promise<void> {
    this.searchForm = this._formBuilder.group({
      year: null,
      make: null,
      model: null
    });
    const list: any = await this._localService.getInventoryJSON().toPromise();
    this.inventory = list;
    this.years = this.getYears(list);
    this.makes = this.getMakes(list);
    if (!this.parameters) {
      this.parameters = new VehicleSearchParameters(null);
      this.searchForm.setValue(this.parameters);
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const { currentValue } = changes.parameters;
    let parameters: VehicleSearchParameters = new VehicleSearchParameters(currentValue);
    if (parameters.make || parameters.year || parameters.model) {
      this.model = { ...parameters };
      if (this.model.make) {
        const { make } = this.model;
        const list: any = await this._localService.getInventoryJSON().toPromise();
        this.models = this.getModelsFromMake(make, list);
      }
    }
  }

  getYears(inventory: Rental[]): String[] {
    let yearSet = new Set();
    inventory.forEach(item => {
      if (item && 'year' in item && !yearSet.has(item.year)) {
        yearSet.add(item.year.toString());
      }
    });
    return <String[]>Array.from(yearSet);
  }

  getMakes(inventory: Rental[]): String[] {
    let makeSet = new Set();
    inventory.forEach(item => {
      if (item && 'make' in item && !makeSet.has(item.make)) {
        makeSet.add(item.make);
      }
    });
    return <String[]>Array.from(makeSet);
  }

  getModelsFromMake(make: String, inventory: Rental[]): String[] {
    let modelSet = new Set();
    inventory.forEach(item => {
      if (item && 'make' in item && 'model' in item && !modelSet.has(item.model) && make.toLowerCase() === item.make.toLowerCase()) {
        modelSet.add(item.model);
      }
    });
    return <String[]>Array.from(modelSet);
  }

  changeYear(event: any): Boolean {
    return false;
  }

  changeMake(event: any): Boolean {
    const { year, make } = this.searchForm.value;
    this.searchForm.setValue(new VehicleSearchParameters({ year, make, model: '' }));
    this.models = this.getModelsFromMake(make, this.inventory);
    return false;
  }

  changeModel(event: any): Boolean {
    return false;
  }

  /**
   * 
   * @returns 
   */
  onSearch(): Boolean {
    this.parametersChange.emit(this.model);
    return false;
  }
}
