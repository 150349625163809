<div class="search-form-container">
    <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
        <div class="row">
            <div class="col-xs-12 col-md-3">
                <div class="search-select-box pt-2 mb-2">
                    <h5 class="ml-2 mb-0">Year</h5>
                    <select class="custom-select mb-3" (change)="changeYear($event)" formControlName="year" [(ngModel)]="model.year">
                        <option value="">All</option>
                        <option *ngFor="let year of years" [ngValue]="year">
                            {{ year }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xs-12 col-md-3">
                <div class="search-select-box pt-2 mb-2">
                    <h5 class="ml-2 mb-0">Make</h5>
                    <select class="custom-select mb-3" (change)="changeMake($event)" formControlName="make" ng-model="my.favorite" [(ngModel)]="model.make">
                        <option value="">All</option>
                        <option *ngFor="let make of makes" [ngValue]="make">
                            {{ make | titlecase }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xs-12 col-md-3">
                <div class="search-select-box pt-2 mb-2">
                    <h5 class="ml-2 mb-0">Model</h5>
                    <select class="custom-select mb-3" (change)="changeModel($event)" formControlName="model" [(ngModel)]="model.model">
                        <option value="">All</option>
                        <option *ngFor="let model of models" [ngValue]="model">
                            {{ model | titlecase }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xs-12 col-md-3">
                <button class="btn hertz-btn pt-2 mb-2 btn-block" [disabled]="!searchForm.valid" type="submit">Continue</button>
            </div>
        </div>
    </form>
</div>