export class VehicleSearchParameters {
    make: string = '';
    model: string = '';
    year: string = '';
    constructor(obj: { make: string; model: string; year: string; }){
        if(!obj){
            return;
        }
        if('make' in obj){
            this.make = obj.make;
        }
        if('model' in obj){
            this.model = obj.model;
        }
        if('year' in obj){
            this.year = obj.year;
        }
    }
}
